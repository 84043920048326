<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container class="form-wrapper">
        <v-toolbar-title class="mb-6 sub-title">Add Company</v-toolbar-title>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Company Name"
              placeholder="Company Name"
              v-model="data.company_name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Description"
              placeholder="Description"
              v-model="data.description"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Address"
              placeholder="Enter Address"
              v-model="data.address"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <!-- </v-row>
        <v-row> -->

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="City"
              placeholder="Enter City"
              v-model="data.city"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
              label="Country"
              placeholder="Enter Country"
              v-model="data.country"
              :rules="[rules.required]"
              :items="countriesOption"
              item-text="name"
              item-value="iso3"
              @change="countryChanged"
              v-bind="basic"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
              :items="stateOptions"
              item-text="name"
              item-value="state_code"
              label="State"
              v-model="data.state"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Postal Code"
              placeholder="Enter Postal Code"
              v-model="data.postal_code"
              maxlength="4"
              :rules="[rules.required, rules.number, rules.postalLength]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="white"
              elevation="2"
              large
              class="mb-2 submit-button"
              v-on:click="storeCompany"
              block
              v-bind="basic"
              :loading="loading"
              >Add Company</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      data: {},
      basic: util.input.basic,
      rules: util.validation,
      stateOptions: util.stateOptions,
      countriesOption: [],
    };
  },
  mounted() {
    this.data = {};
    this.fetchCountry();
  },
  methods: {
    countryChanged(d) {
      let selectedCountry = this.countriesOption.find((c) => {
        return c.iso3 == d;
      });
      selectedCountry
        ? (this.stateOptions = selectedCountry.states)
        : (this.stateOptions = []);
    },
    fetchCountry() {
      axios({
        url: "/static/country.json",
      })
        .then((resp) => {
          this.countriesOption = resp.data.data;
        })
        .catch();
    },
    storeCompany() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        util
          .http({
            url: "company",
            method: "post",
            data: this.data,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Company Stored");
              this.$emit("companyStored", true);
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
      }
    },
  },
};
</script>
