<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container class="form-wrapper">
        <v-toolbar-title class="mb-6 sub-title">Customer Type</v-toolbar-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              label="Name"
              placeholder="Name"
              v-model="data.name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              label="Description"
              placeholder="Description"
              v-model="data.description"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="white"
              elevation="2"
              large
              class="mb-2 submit-button"
              v-on:click="submit"
              block
              v-bind="basic"
              :loading="loading"
              >Add Customer Type</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  data() {
    return {
      loading: false,
      data: {},
      basic: util.input.basic,
      rules: util.validation,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        util
          .http({
            url: "customer-type",
            method: "post",
            data: this.data,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Customer Type Stored");
              this.$emit("customerTypeStored", true);
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
      }
    },
  },
};
</script>
